import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlTVShows, dbUrlWTDlist, dbUrlTVNetworksLists, customStyles2 } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
//import ReactMarkdown from 'react-markdown';
import ReactPlayer from "react-player";
import { SearchLists, SelectProperty } from "../misc/utils";
import Select from 'react-select';
import {DebounceInput} from 'react-debounce-input';

const TVLists = () => {
    const [tvShows, setTVShows] = useState([])
    const [list, setList] = useState([])
    const [networks, setNetworks] = useState([])
    const [optionNetwork, setOptionNetwork] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [loading3, setLoading3] = useState(true)
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [errorNetwork, setErrorNetwork] = useState(null)

    const params = useParams();

    //console.log('shows - ' + dbUrlTVShows + params.slug);
    //console.log('networks - ' + dbUrlTVNetworksLists + params.slug);
    
    useEffect(() => {
        const fetchList = () => {
            axios.get(dbUrlWTDlist + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchTVShows = () => {
          axios.get(dbUrlTVShows + params.slug)
          .then(response => {
            setLoading2(false)
            setTVShows(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        const fetchNetworks = () => {
            axios.get(dbUrlTVNetworksLists + params.slug)
            .then(response => {
              setLoading3(false)
              setNetworks(response.data)
            }).catch(errorNetwork => {
              setErrorNetwork(errorNetwork);
            });
          }

        fetchList()
        fetchTVShows()
        fetchNetworks()
      }, [params.slug])
  
    if (error) return `Error tv: ${error.message}`;
    if (errorList) return `Error list: ${errorList.message}`;
    if (errorNetwork) return `Error network: ${errorNetwork.message}`;

    //console.log(networks);

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    if (loading || loading2 || loading3) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                
                                    <h1>Oops! You seem to be lost.</h1>
                                    <p>Here are some helpful links:</p>

                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                        <li><Link to='/allbooks'>All Books</Link></li>
                                        <li><Link to='/allquotes'>All Quotes</Link></li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    let filterArray, NetworkOptions

    const {name, description, pic_link, page} = list[0];

    NetworkOptions = SelectProperty(networks, 'name')

    const selectedOptionNetwork = (selectedNetwork) => {
        setOptionNetwork(selectedNetwork);
    }

    //Search
    if (searchString) {
        filterArray = SearchLists(tvShows, 'searchtitle', searchString.toLowerCase());
        } 
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

    //FILTERS
    if (optionNetwork) {
        filterArray = tvShows.filter(e => e.network.includes(optionNetwork.value));
        } 
    if (!optionNetwork && !searchString) {
        filterArray = tvShows;
    }

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>

                <h1>{value.name}</h1>
            
                <div className="row mb-3 gx-5">
                    
                    {/* kolonne1 */}
                    <div className="col-sm-4 Description">

                        <div className="mt-2"><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>

                        {(value.in_progress || (window.location.hostname === "localhost")) &&
                            <div>
                                
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                
                                
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs">total count: {tvShows.length}</div>
                                </div> 
                            </div>
                            }
                        
                    </div>

                    {/* kolonne2 */}
                    <div className="col-sm-4 Description">
                        {value.description &&
                            <div className="factText14 rounded my-2 p-2">{value.description}</div>
                        }

                        {value.source_url &&
                            <div className="px-2">
                                <b>List source</b>
                                <div className="Description">
                                    <ul>
                                        <li>
                                            <b><a href={value.source_url}>
                                                {value.source}
                                            </a></b>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        }
                        
                        {value.comment1 && 
                            <div className="card bg-light mt-2 py-2 px-2">
                                <div className="Quote mb-3">{value.comment1}</div> 
                                <div className="blockquote-footer"><i>{value.source1} ({value.details1})</i></div>
                            </div>  
                        }
                        {value.comment2 && 
                            <div className="card bg-light mt-3 py-2 px-2">
                                <div className="Quote mb-3">{value.comment2}</div> 
                                <div className="blockquote-footer"><i>{value.source2} ({value.details2})</i></div>
                            </div>  
                        }
                       
                    </div>

                    {/* kolonne3 */}
                    <div className="col-sm-4">
                        {!searchString &&
                            <div>
                                <h4>Filter</h4>
                                <i>--Find where to stream--</i>
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={optionNetwork}
                                            onChange={selectedOptionNetwork}
                                            options={NetworkOptions}
                                            placeholder='Select streaming service..'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => setOptionNetwork(null)} >Reset</button>
                                    </div>
                                </div>
                            </div>
                        }

                        {!optionNetwork &&
                        <div>
                            <div className="row mt-3">   
                                    <div className="col">
                                    <h4>Search</h4>
                                </div>
                            </div>
                            <div className="row">  
                                <div className="col-9">
                                    <div className="search">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={1000}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all titles" 
                                        />
                                    </div>
                                </div>
                                <div className="col-3 pl-0">
                                    <button type="button" className="btn btn-primary"  
                                    onClick={() => clearSearchField()} >Reset</button>
                                </div>
                            </div>
                        </div>
                        }   

                        <div className="mt-4"><b>Check out</b></div>
                            <div className="Description">
                                <ul>
                                    {
                                        (() => {
                                        if (value.link1 && value.link2 && value.link3 && value.link4) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url3}>
                                                        {value.link3}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url4}>
                                                        {value.link4}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                        if (value.link1 && value.link2 && value.link3) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url3}>
                                                        {value.link3}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                        if (value.link1 && value.link2) {
                                        return  <div><li><b><a href={value.url1}>
                                                    {value.link1}
                                                </a></b>
                                                </li>
                                                <li><b><a href={value.url2}>
                                                    {value.link2}
                                                </a></b>
                                                </li>
                                                </div>
                                        }
                                        return <div><li><b><a href={value.url1}>
                                                    {value.link1}
                                                </a></b>
                                                </li>
                                                </div>
                                        })()
                                    }
                                    
                                </ul>
                            </div>      
                        
                        
                    </div>
                </div>
                   
            </React.Fragment>
            )
    })

    const TVItems = filterArray.map(value => {

        return (

            <React.Fragment key={value.id}>
                <div className="row pt-3 pb-3 border-bottom border-top border-info">
                    {/* 1 kolonne */}
                    <div className="col-sm-3">

                        {
                            (() => {
                            if (value.rank) {
                            return  <div className='Title text-center mb-2 rounded'><b>{value.rank} - {value.title}</b></div>  
                            }
                            return <div className='Title text-center mb-2 rounded'><b>{value.title}</b></div> 
                            })()
                        }

                        <div align="center">
                            <img src={value.poster} className="img-fluid rounded" alt="">
                            </img>
                        </div>

                        {value.created_by &&
                            <div className='factText rounded text-center my-2 p-2'><b>Created by:</b> {value.created_by}</div>
                        }
                        {value.developed_by &&
                            <div className='factText rounded text-center my-2 p-2'><b>Developed by:</b> {value.developed_by}</div>
                        }
                        {value.directed_by &&
                            <div className='factText rounded text-center my-2 p-2'><b>Directed by:</b> {value.directed_by}</div>
                        }
                        {value.presented_by &&
                            <div className='factText rounded text-center my-2 p-2'><b>Presented by:</b> {value.presented_by}</div>
                        }
                        {value.based_on &&
                            <div className='factText rounded text-center my-2 p-2'><b>Based on:</b> {value.based_on}</div>
                        }

                        {value.trailer &&
                            <div>
                                <div className='mt-3' align='center'><b>Trailer - click to watch</b></div>
                                    <div className="mt-1 border">
                                        <ReactPlayer
                                            url={ value.trailer }
                                                    light='true'
                                                    //light
                                                    controls
                                                    width="280"
                                                    height="200"
                                        />
                                    </div>
                            </div>
                        }

                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-5 pb-3 border-right">

                        <div className="factText rounded my-2 p-2"> 
                                    <ReadMoreAndLess
                                        charLimit={1000}
                                        readMoreText={" Read more ▼"}
                                        readLessText={" Read less ▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                    {value.description}
                                </ReadMoreAndLess> 
                                    {value.wiki &&
                                    <div className="fst-italic text-end p-1">
                                     - <a href={value.wiki}>wikipedia..</a>
                                    </div>
                                    }
                        </div>

                        {value.comment1 && 
                            <div className="card bg-light mt-3 py-2 px-2">
                                <div className="Quote mb-3">{value.comment1}</div> 
                                <div className="blockquote-footer"><i>{value.source1} ({value.details1})</i></div>
                            </div>  
                        }
                        {value.comment2 && 
                            <div className="card bg-light mt-3 py-2 px-2">
                                <div className="Quote mb-3">{value.comment2}</div> 
                                <div className="blockquote-footer"><i>{value.source2} ({value.details2})</i></div>
                            </div>  
                        }

                    </div>

                    {/* 3 kolonne */}
                    <div className="col-sm-4 pb-4">

                        <div className="row">

                            <div className="col pr-0 mr-2">
                                    <div>
                                        {value.country &&
                                            <div><b>Country </b><br />
                                                <div className="DateTxt rounded p-2">{value.country}</div>
                                            </div>
                                        }

                                        {value.language &&
                                        <div>
                                            <b>Original language</b> <br />
                                            <div className="DateTxt rounded p-2">{value.language}</div>
                                        </div>
                                        }
                                        {value.genre &&
                                            <div>
                                                <b>Genre</b> <br />
                                                <div className="DateTxt rounded p-2">{value.genre}</div>
                                            </div>
                                        }
                                        {value.imdb_rating &&
                                            <div>
                                                <b>IMDb rating</b> <br />
                                                <div className="DateTxt rounded p-2"><b>{value.imdb_rating}</b></div>
                                            </div>
                                        }
                                        
                                    </div>

                            </div>

                            <div className="col pl-1">
                                {value.released &&
                                    <div>
                                        <b>Release date</b> <br />
                                        <div className="Date rounded p-2">{value.released}</div>
                                    </div>
                                }

                                {value.seasons &&
                                    <div>
                                        <b>No. of seasons</b> <br />
                                        <div className="DateTxt rounded p-2">{value.seasons}</div>
                                    </div>
                                }
                                {value.no_episodes &&
                                    <div>
                                        <b>No. of episodes</b> <br />
                                        <div className="DateTxt rounded p-2">{value.no_episodes}</div>
                                    </div>
                                }
                                {value.running_time &&
                                    <div>
                                        <b>Running time</b> <br />
                                        <div className="DateTxt rounded p-2">{value.running_time}</div>
                                    </div>
                                }
            
                            </div>

                        </div>

                        <div className="row pt-3">
                            <div className="col-12">
                                {value.starring &&
                                <div><b>Starring</b> <br />
                                    <div className="factText rounded p-2">  
                                        <ReadMoreAndLess
                                            charLimit={100}
                                            readMoreText={" Read more ▼"}
                                            readLessText={" Read less ▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                        >
                                        {value.starring}
                                    </ReadMoreAndLess>
                                    </div>
                                </div>
                                }

                                {value.voices_of &&
                                <div><b>Voices of</b> <br />
                                    <div className="factText rounded p-2">  
                                        <ReadMoreAndLess
                                            charLimit={100}
                                            readMoreText={" Read more ▼"}
                                            readLessText={" Read less ▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                        >
                                        {value.voices_of}
                                    </ReadMoreAndLess>
                                    </div>
                                </div>
                                }

                                
                            </div>
                        </div>

                        <div className="p-3 mt-2 border rounded bg-success text-white Description">
                            <b>Where:</b> <br />
                            {value.network} 
                        </div>

                        <div className="row">
                            <div className="col pt-3 mb-3">
                                    {
                                            (() => {
                                            if (value.comment_2) {
                                            return <div className="card bg-light p-2">
                                                        <div className="quote3 mb-3">{value.comment_2}</div> 
                                                        
                                                        {
                                                            (() => {
                                                                if (value.source_txt_details_2) {
                                                                    return <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                                                }
                                                                return <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>

                                                            })()

                                                        }

                                                    </div>
                                            }
                                            return 
                                        })()
                                    }

                            </div>

                        </div>

                        {
                            (() => {
                            if (value.video1 && !value.video_tracks_center) {
                                return <div>
                                            <div className="row">
                                                <div className='col-12'>
                                                    <div className='videoTitle pl-2 mx-2' align='center'><strong>My selected tracks</strong></div>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-6 mb-2'>
                                                    <div className="card bg-light p-2">
                                                        <div className="mx-1">  
                                                            <ReactPlayer
                                                                url={ value.video1 }
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        width="280"
                                                                        height="200"
                                                            />
                                                        </div>
                                                        <div className='DescriptionBig2 pl-1'>
                                                            <strong>{value.desc1}</strong>
                                                        </div>
                                                    </div>
                                                    </div>
                                                <div className='col-sm-6 mb-2'>
                                                    {
                                                        (() => {
                                                        if (value.video2) {
                                                            return <div className="card bg-light p-2">
                                                                        <div className="mx-1">  
                                                                            <ReactPlayer
                                                                                url={ value.video2 }
                                                                                        light='true'
                                                                                        //light
                                                                                        controls
                                                                                        width="280"
                                                                                        height="200"
                                                                            />
                                                                        </div>
                                                                        <div className='DescriptionBig2 pl-1'>
                                                                            <strong>{value.desc2}</strong>
                                                                        </div>
                                                                    </div>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                    </div>
                            }
                            return 
                            })()
                        }
                        {
                            (() => {
                            if (value.video3 && !value.video_tracks_center) {
                                return <div>
                                            <div className="row mt-1">
                                                <div className='col-sm-6 mb-2'>
                                                    <div className="card bg-light p-2">
                                                        <div className="mx-1">  
                                                            <ReactPlayer
                                                                url={ value.video3 }
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        width="280"
                                                                        height="200"
                                                            />
                                                        </div>
                                                        <div className='DescriptionBig2 pl-1'>
                                                            <strong>{value.desc3}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 mb-2'>
                                                    {
                                                        (() => {
                                                        if (value.video4) {
                                                            return <div className="card bg-light p-2">
                                                                        <div className="mx-1">  
                                                                            <ReactPlayer
                                                                                url={ value.video4 }
                                                                                        light='true'
                                                                                        //light
                                                                                        controls
                                                                                        width="280"
                                                                                        height="200"
                                                                            />
                                                                        </div>
                                                                        <div className='DescriptionBig2 pl-1'>
                                                                            <strong>{value.desc4}</strong>
                                                                        </div>
                                                                    </div>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                            }
                            return 
                            })()
                        }

                    </div>
                </div>
                
            </React.Fragment>

        )

    })
    
    return (
        <main className="my-1 py-1">
            {tvShows.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                             {TVItems} 
                        </div>
                    </div>

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>


                </div>
            )}
        </main>
    )
}

export default TVLists