import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURL } from './Constants';
import './wisdom.css';
import SEO from "../SEO";
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
import {DebounceInput} from 'react-debounce-input';
import Select from 'react-select';
import { SelectProperty, SearchLists } from "./misc/utils"

const KindleHighlights = () => {

    const [listsData, setlistsData] = useState([])
    const [loadingLists, setLoadingLists] = useState(true)
    const [errorLists, setErrorLists] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [optionCategory, setOptionCategory] = useState(null)

    let filterArray, CategoryOptions

    useEffect(() => {
        const fetchListsData = () => {
          axios.get(dbURL + 'kindlehighlights')
          .then(response => {
            setLoadingLists(false)
            setlistsData(response.data)
          }).catch(errorLists => {
            setErrorLists(errorLists);
          });
        }

        fetchListsData()
      }, [])
  
    if (errorLists) return `Error List: ${errorLists.message}`;

    CategoryOptions = SelectProperty(listsData, 'category')

    const selectedOptionCategory = (selectedCategory) => {
        setOptionCategory(selectedCategory);
    } 

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    if (loadingLists) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
      };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

    if (searchString) {
        filterArray = SearchLists(listsData, 'title', searchString.toLowerCase());
        } 
    if (optionCategory) {
        filterArray = listsData.filter(e => e.category === optionCategory.value);
        } 

    if (!optionCategory && !searchString) {
        filterArray = listsData;  
        } 


    const ListItems = filterArray.map(value => {

        let page = '/' + value.page;

        return (
            <React.Fragment key={value.id}>

                <div>
                    <div className="row BookSuperslim mx-1 my-4 p-2 border rounded border-4">
                        <div className='col'>
                            {value.picture &&
                            <div className="float-md-end ms-md-2">
                                <Link to={page}>
                                <img border="0" alt="album" src={value.picture} width="75" height="110" />
                                </Link>
                            </div>  
                            }
                            <div className="BookSlimTitle"><b><Link to={page}>{value.title}</Link></b></div>
                            {value.author &&
                                <div>&nbsp;{value.author} ({value.published_year})</div> 
                            }
                            {value.type === 'Kindle highlights' &&
                                <h4><div className="badge bg-primary text-wrap">{value.type}</div>
                                </h4>
                            }
                            {value.type !== 'Kindle highlights' &&
                                <h4><div className="badge bg-success text-wrap">{value.type}</div>
                                </h4>
                            }
                            
                        </div>
                    </div>
                </div>

            </React.Fragment>          
        )

    })
   

    return (
        <main className="my-1 py-1">
            {listsData.length > 0 && (
                <div className="container">

                 <SEO
                    title={'My Kindle Highlights'}
                    description={'All books with kindle highlights'}
                    name={'Kindle Highlights'}
                    url={'https://www.wisdomthisday.com/kindlehighlights'}
                    picture_link={''}
                    canonical={'https://www.wisdomthisday.com/kindlehighlights'}
                    type='article' />
                
                <h1>Book Summaries & Highlights</h1>
                
                <div className="row">
                        <div className="col-sm-3 mb-3">
                            <img src='https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/13ffc30d-56e8-4d4c-3d2d-ca602dc93200/public' 
                            alt="plato" className="img-fluid rounded mx-auto d-block" />
                            <br />
                            <b>Count: {filterArray.length}</b>
                        </div>

                        <div className="col-sm-5">
                               
                                
                                <strong>I love books & my kindle.</strong><br />
                                <br />
                                <p>Here is a list of books where I have publihed my "kindle highlights" and books where I have 
                                created a summary.</p>

                                <p><strong>Kindle highlights</strong> = text from the books that I have highighted.</p>

                                <p>All highlights are "raw dumps", not <i>beautified</i> in any manner..</p>
                                    
                                <p><strong>click on title/book to visit Summaries/highlights from chosen book.</strong></p>

                                
                        </div>
                        
                        <div className="col-sm-4 mb-4 border-start border-info">

                            {(!searchString)  &&
                                <div className="row">   
                                    <div className="col">
                                        <h5>Filters</h5>
                                    </div>
                                </div>
                            }

                            {(!searchString)  && 
                                <div className="row pb-2"> 
                                    <div className="col-6">
                                            <Select
                                                //styles={{control: customControlStyles}}
                                                value={optionCategory}
                                                onChange={selectedOptionCategory}
                                                options={CategoryOptions}
                                                placeholder={'select category..'}

                                            />
                                    </div>

                                    <div className="col-6"> 
                                        <button type="button" className="btn btn-primary" onClick={() => setOptionCategory(null)}> Reset filter</button>
                                    </div> 

                                </div>
                            }

                            {(!optionCategory)  &&  
                                <div className="row mt-2">   
                                        <div className="col">
                                        <h5>Search lists</h5>
                                    </div>
                                </div>
                            }
                            {(!optionCategory)  &&  
                            <div className="row mb-1">  
                                
                                <div className="col-6">
                                    <div className="search">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={1000}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all titles" 
                                        />
                                    </div>
                                </div>
                                <div className="col-6 pl-0">
                                    <button type="button" className="btn btn-primary"  
                                    onClick={() => clearSearchField()}>Reset</button>
                                </div>

                            </div>

                               
                            
                            
                            }
                           

                        </div>
                    </div>

                    <div className="row">
                        <div className="col" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                    
                    
                    <div className="row">
                        <div className="col-sm-12">      
                                 {ListItems} 
                        </div>
                    </div>

                    <div className="row">
                        <div className="col" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                            <b>Scroll to top</b>
                            </button>
                        </div>
                    </div>

                </div>
                
            )}
        </main>
    )


}

export default KindleHighlights