import React, { Component } from 'react';
import axios from 'axios';
import { dbURL } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
//import ReadMoreAndLess from "react-read-more-less";
import {DebounceInput} from 'react-debounce-input';
import Highlighter from "react-highlight-words";
//import Select from 'react-select';
import ReactMarkdown from 'react-markdown';

class BookSummaries extends Component {

    state = {
        BookHeadingData: [],
        BookQuotesData: [],
        SearchString: '',
        loading: true
      }

    getBookHeadingItem(){
        axios.get(dbURL + 'booksummary')
          .then(response => {
            this.setState({
                BookHeadingData: response.data
            });
          });
      } 

    getBookQuotesItems(){
        axios.get(dbURL + this.props.BookAPI)
          .then(response => {
            this.setState({
                BookQuotesData: response.data,
              loading: false
            });
          });
      } 
    
    componentDidMount() {
        this.getBookHeadingItem()
        this.getBookQuotesItems()
      }

      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
        this.setState({SearchString: event.target.value});
        } 
    
      clearSearchField(){
        this.setState({
          SearchString: '', 
            })
        }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    render() {

        const { SearchString } = this.state;
        const BookHeading = this.state.BookHeadingData;
        const BookQuotesItems = this.state.BookQuotesData;
        

        
        let filterArray, searchWords


        function SearchQuotes(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < BookQuotesItems.length; i++) {
                //console.table(AllItems);
                if (BookQuotesItems[i].search_book_quotes.includes(searchText))
                
                    {searchArray.push(BookQuotesItems[i]);}
                    
            } 
            //console.log(searchArray);
            return searchArray
        }

        if (SearchString) {
            searchWords = SearchString.split(/\s/).filter(word => word)

            filterArray = SearchQuotes(SearchString.toLowerCase());
            } 
        if (!SearchString) {
            filterArray = BookQuotesItems;
        }

        const SelectedBookHeader = BookHeading.map(value => {

            return (
                <React.Fragment key={value.id}>

                    <div className="row mb-3">
                        {/* SJEKKE PÅ MOBIL */}

                        <div className="col-sm-4">
                            <img src={value.book_picture}
                            alt="alt" className="img-fluid rounded mx-auto d-block" />
                            <div className="btn btn-secondary btn-xs mt-3 mb-2 ml-5">Number of quotes: {BookQuotesItems.length}</div>
                            
                        </div>

                        <div className="col-sm-8">
                            <h3>{value.summary_title}</h3>
                            
                            <div className="Description mb-2">{value.description}</div>
                            
                             {
                                (() => {
                                if (value.book_comment && value.source_details) {
                                    return <div className="card bg-light py-1 px-2 my-3 Description">
                                                <p className="quote3">{value.book_comment}</p> 
                                                <div className="blockquote-footer"><i>{value.source} ({value.source_details})</i></div>
                                            
                                            </div>
                                }
                                if (value.book_comment && !value.source_details) {
                                    return <div className="card bg-light py-1 px-2 my-3 Description">
                                                <div className="quote3 mb-3">{value.book_comment}</div> 
                                                <div className="blockquote-footer"><i>{value.source}</i></div>
                                            
                                            </div>
                                }
                                return 
                                })()
                            }


                             <div className="row">
                                 <div className="col-sm-7">
                                    <b>Sources</b>
                                    
                                    <ul>
                                        <li>Any sources?</li>

                                    </ul>
                                    
                                    <b>Also check out:</b>
                                    <div className="Description">

                                        <ul>
                                            <li>Andre ting ?</li>
                                        </ul>
                                
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div>
                                        
                                        <div className="row">   
                                                <div className="col">
                                                <h5>Search</h5>
                                            </div>
                                        </div>
                                
                                        <div className="row pb-2">  
                                                <div className="col-8">
                                                <DebounceInput
                                                        //type="number"
                                                        minLength={3}
                                                        debounceTimeout={500}
                                                        value={SearchString}
                                                        onChange={this.handleSearchChange}
                                                        placeholder=" Search all Quotes" 
                                                    />
                                                </div>
                                                <div className="col-4 pl-0">
                                                    <button type="button" className="btn btn-primary btn-sm"  
                                                    onClick={() => this.clearSearchField()} >Reset</button>
                                                </div>
                                        </div>
                                        {this.state.SearchString && 
                                                    <div className="Description">    
                                                    <b>Quote hits: {filterArray.length}</b>
                                                    </div>
                                                }
                                    </div>
                                        

                                
                                </div>
                            
                            </div>

                                


                        </div>

                    </div>

                </React.Fragment>
            )
            }
        )

        const QuoteItems = filterArray.map(value => {
            
            return (

                <React.Fragment key={value.id}>

                    <div className="row SummaryChapter2 justify-content-center mt-3 mx-5">
                        <div className="col"> 
                            {
                                (() => {
                                    if (value.new_chapter) {
                                        return <div className="center">&nbsp;{value.chapter}</div>
                                        }
                                    return 
                                })()
                            }
                        </div>
                    
                    </div>

                    <div className="row py-1 mb-1">

                        
                        {/* 1 kolonne */}
                        <div className="col Description">

                            {
                                (() => {
                                    if (value.picture && value.pic_width) {
                                        return <div className="text-center mb-4 mx-3">
                                            <img src={value.picture} className="img-fluid" alt="" width={value.pic_width}></img>
                                            </div>
                                        }
                                    if (value.picture && !value.pic_width) {
                                        return <div className="text-center mb-4 mx-3">
                                            <img src={value.picture} className="img-fluid" alt=""></img>
                                        </div>
                                    }
                                    return 
                                })()
                            }

                            {
                                (() => {
                                if (SearchString) {
                                    return  <div className="bg-light ml-2 mr-2">
                                                <div className="py-2 px-2">
                                                
                                                        
                                                        <div className="card bg-light py-2 px-2 mt-3">
                                                            <div className="quote3">
                                                                <Highlighter
                                                                    highlightClassName="YourHighlightClass"
                                                                    searchWords={searchWords}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.quote}
                                                                />
                                                            </div> 
                                                        </div>
                                                
                                                
                                            
                                            
                                                </div>
                                            </div>
                                            


                                }
                                return <div className="row justify-content-center">
                                            <div className="col-10">
                                                <div>
                                                    <div className="card text-center bg-light px-2 py-2">
                                                        
                                                            <ReactMarkdown>
                                                                {value.quote}
                                                            </ReactMarkdown>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>    
                                })()
                            }


                        </div>
                        
                       


                    </div>


                </React.Fragment>
            )

        })


        return (

            <main className="my-1 py-1">
                <div className="container">
                            
                    {SelectedBookHeader}

                    <div className="row">
                        <div className="col pb-2 mr-4" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                    {
                        (() => {
                        if (this.state.loading) {
                            return <div><br /><h5><i>Loading session...</i></h5></div>
                        }
                        return <div> 
                                    {QuoteItems}
                            </div>
                        })()
                    }

                    <div className="mr-3">
                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>
                    </div>
              
                </div>
            </main>




        )
    }
}

export default BookSummaries