import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURL, dbUrlBookHighlightsHeading } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
//import Select from 'react-select';
import { SearchLists } from "../misc/utils";
import {DebounceInput} from 'react-debounce-input';
import Highlighter from "react-highlight-words";

const BooksHighlights = (props) => {
    const [bookhighlights, setBookHighlights] = useState([])
    const [book, setBook] = useState([])
    const [searchString, setSearchString] = useState('')
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [errorHighlights, setErrorHighlights] = useState(null)
    const [errorBook, setErrorBook] = useState(null)

    const params = useParams();

    // Custom component to style images for ReactMarkdown
    const FluidImage = ({ alt, src }) => (
        <img src={src} alt={alt} style={{ maxWidth: '100%', height: 'auto' }} />
    );

    useEffect(() => {
        const fetchBook = () => {
            axios.get(dbUrlBookHighlightsHeading + params.slug)
            .then(response => {
                setLoading(false)
                setBook(response.data)
            }).catch(errorBook => {
                setErrorBook(errorBook);
            });
        }

        const fetchBooksHighlights = () => {
          axios.get(dbURL + props.BookAPI + params.slug)
          .then(response => {
            setLoading2(false)
            setBookHighlights(response.data)
          }).catch(errorHighlights => {
            setErrorHighlights(errorHighlights);
          });
        } 

        fetchBook()
        fetchBooksHighlights()
      }, [params.slug, props.BookAPI])
  
    if (errorBook) return `Error getting book: ${errorBook.message}`;
    if (errorHighlights) return `Error getting highlights: ${errorHighlights.message}`;

    if (loading || loading2) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (bookhighlights.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>Oops! I can't find the book..</h1>
                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const {name, description, pic_link, page, kindle_highlights, type} = book[0];
   let filterArray, searchWords

    //Search
     if (searchString && !kindle_highlights) {
        searchWords = searchString.split(/\s/).filter(word => word)
        filterArray = SearchLists(bookhighlights, 'quote', searchString.toLowerCase());
        } 
    if (searchString && kindle_highlights) {
        searchWords = searchString.split(/\s/).filter(word => word)
        filterArray = SearchLists(bookhighlights, 'kindle_highlights', searchString.toLowerCase());
        } 
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        } 
 

    if (!searchString) {
        filterArray = bookhighlights;
    }
   

    

    const BookHeading = book.map(value => {
        
        return (
            <React.Fragment key={value.id}>
            
                <div className="row mb-2 gx-5">
                    
                    {/* kolonne1 */}
                    <div className="col-sm-3 mt-1 Description">

                        <div><img src={value.picture} alt="alt" className="img-fluid" /> 
                        </div>

                        {(value.in_progress || (window.location.hostname === "localhost")) &&
                            <div>
                                
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                
                                
                                 <div className="text-center">
                                    <div className="btn btn-info btn-xs mb-2">ALL count: {bookhighlights.length}</div>
                                </div>  
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs">FILTER count: {filterArray.length}</div>
                                </div>
                            </div>
                        }
                        
                    </div>

                    {/* kolonne2 */}
                    <div className="col-sm-5 mt-1">

                        <h2>{value.title}</h2>

                        {value.author &&

                            // <div>&nbsp;{value.author} ({value.published_year})</div> 
                            <h5 className="card-title mt-3">&nbsp;{value.author} ({value.published_year})</h5>
                        }

                        
                        {value.book_description &&
                            <div className="factText16 rounded mt-2 p-2">
                                 <ReadMoreAndLess
                                        charLimit={600}
                                        readMoreText={" Read more ▼"}
                                        readLessText={" Read less ▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                        {value.book_description}    
                                    </ReadMoreAndLess>
                                                
                            </div>
                        }

                        {value.more_info &&
                            <div className="mt-3">
                                <b>Amazon link</b>
                                <div className="Description">
                                    <ul>
                                        <li>
                                            <b><a href={value.more_info}>
                                                {value.title} (amazon)
                                            </a></b>
                                        </li>  
                                    </ul>
                                </div>
                            </div>
                        }
                        
                        {value.comment1 && 
                            <figure className="mt-2 p-2">
                                <blockquote className="blockquote">
                                    {value.comment1}
                                </blockquote>
                                {value.details1 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source1} <cite title="Source Title">({value.details1})</cite>
                                </figcaption>
                                }
                                {!value.details1 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source1}
                                </figcaption>
                                }
                            </figure>
                        }
                        {value.comment2 && 
                            <figure className="mt-2 p-2">
                                <blockquote className="blockquote">
                                    {value.comment2}
                                </blockquote>
                                {value.details2 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source2} <cite title="Source Title">({value.details2})</cite>
                                </figcaption>
                                }
                                {!value.details2 &&
                                <figcaption className="blockquote-source-small ms-2">
                                    - {value.source2}
                                </figcaption>
                                }
                            </figure>
                        }
                        

                        
                        
                       
                    </div>

                    {/* kolonne3 */}
                    <div className="col-sm-4 mt-1">
                                       
                        <div className="row">   
                                <div className="col">
                                <h5>Search</h5>
                            </div>
                        </div>

                        <div className="row">  
                            <div className="col-9">
                                <div className="search">    
                                    <DebounceInput
                                        //type="number"
                                        minLength={3}
                                        debounceTimeout={1000}
                                        value={searchString}
                                        onChange={handleSearchChange}
                                        placeholder="Search all highlights" 
                                    />
                                </div>
                            </div>
                            <div className="col-3 pl-0">
                                <button type="button" className="btn btn-primary"  
                                onClick={() => clearSearchField()}>Reset</button>
                            </div>
                        </div>

                        {searchString && !kindle_highlights &&
                                <div>
                                    <div className='mt-3'>
                                        <div className="btn btn-info btn-xs">total hits: {filterArray.length}</div>
                                    </div> 
                                </div>
                        }
                        {searchString && kindle_highlights &&
                                <div>
                                    <div className='mt-3'>
                                        <div className="btn btn-info btn-xs">Search results marked in green.</div>
                                    </div> 
                                </div>
                        }

                        {value.description &&
                            <div className="SummaryChapter2 rounded mt-5 p-2">
                                <ReactMarkdown>
                                    {value.description}      
                                </ReactMarkdown>
                            </div>
                        }

                        <div className="mt-3">
                            <h5>Book Summaries & Highlights</h5>
                            <ul>
                                <li><Link to='/kindlehighlights'>List of book Summaries & Highlights</Link></li>
                            </ul>
                        </div>

                    </div>
                        
                </div>  

                <div className="row">
                    <div className="col pb-1 me-2" align="Right"> 
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                        <b>Scroll down</b>
                        </button>
                    </div>
                </div>


                {type === 'Book summary' &&
                <div className="row">
                    <div className="col">
                        <div>
                            {!searchString &&
                                <div className="p-3 mt-2 mb-3 ms-3 SummaryChapter2 rounded">
                                    <ReactMarkdown
                                        components={{
                                            img: ({ node, ...props }) => <FluidImage {...props} />,
                                        }}
                                        >
                                        {value.kindle_highlights}
                                    </ReactMarkdown>
                                </div>
                                }
                            {searchString &&
                            <div className="p-3 mt-2 mb-3 ms-3 SummaryChapter2 rounded">
                                <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={searchWords}
                                    autoEscape={true}
                                    textToHighlight={value.kindle_highlights}
                                />
                            </div> 
                            }
                        </div>
                    </div>
                </div>
                }
                   
            </React.Fragment>
            )
    })

    const highlights = filterArray.map(value => {

        return (

            <React.Fragment key={value.id}>

                {type === 'Kindle highlights' &&
                    <div>
                        {value.chapter && value.location &&
                            <div>
                                {value.chapter} | {value.location}
                            </div>
                        }
                        {!value.chapter && !value.page && value.location &&
                            <div>
                                Location | {value.location}
                            </div>
                        }
                        {value.page && !value.chapter &&
                            <div>
                                Page | {value.page}
                            </div>
                        }

                        {!searchString &&
                        <div className="p-2 mt-2 mb-3 ms-3 SummaryChapter2 rounded">
                                {value.quote}
                        </div>
                        }
                        {searchString &&
                        <div className="p-2 mt-2 mb-3 ms-3 SummaryChapter2 rounded">
                            <Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={value.quote}
                            />
                        </div> 
                        }
                    </div>
                }
                

            </React.Fragment>
        )

    })

    

    return (
        <main className="my-1 py-1">
            {BookHeading.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {BookHeading}
                        </div>
                    </div>
                    
                    {type === 'Kindle highlights' &&
                    <div className="row">
                        <div className="col">
                             {highlights} 
                        </div>
                    </div> 
                    }

                   

                    <div className="me-2" align="Right">
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </div>


                </div>
            )}
        </main>
    )

}

export default BooksHighlights