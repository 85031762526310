import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlWineSubRegions, dbUrlWTDlist, dbURListsRefswtd } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { SearchLists, Mobile } from "../misc/utils";
//import Select from 'react-select';
import {DebounceInput} from 'react-debounce-input';

const WineRegions = () => {
    const [wineregions, setWineregions] = useState([])
    const [list, setList] = useState([])
    const [searchString, setSearchString] = useState('')
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [loading3, setLoading3] = useState(true)
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [references, setReferences] = useState([])
    const [errorReferences, setErrorReferences] = useState(null)

    const params = useParams();

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbUrlWTDlist + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchRegions = () => {
          axios.get(dbUrlWineSubRegions + params.slug)
          .then(response => {
            setLoading2(false)
            setWineregions(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefswtd + params.slug)
            .then(response => {
                setLoading3(false)
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        fetchList()
        fetchRegions()
        fetchReferences()
      }, [params.slug])
  
    if (error) return `Error tv: ${error.message}`;
    if (errorList) return `Error list: ${errorList.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;

    const isMobile = Mobile();

    if (loading || loading2 || loading3) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    //console.log(list);

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                
                                    <h1>Oops! You seem to be lost.</h1>

                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    let filterArray, sortedReferences;

    sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });

    //Search
    if (searchString) {
        filterArray = SearchLists(wineregions, 'name', searchString.toLowerCase());
        } 
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

   
    if (!searchString ) {
        filterArray = wineregions;
    }

    function LinkRenderer(props: any) {
        return (
          <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        );
      }

    const {name, description, pic_link, page} = list[0];

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>

                <div className="ms-2"><h1>{value.name}</h1></div>
            
                <div className="row mb-2 gx-5">
                    
                    {/* kolonne1 */}
                    <div className="col-sm-5 mt-1 Description">

                        <div><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>
                        <div className="text-center">{value.pic_name}</div>


                        {value.pic2_link &&
                        <div className="mt-4 text-center">
                            <img src={value.pic2_link} alt="alt" className="img-fluid" /> 
                        </div>
                        }
                        {value.pic2_name &&
                            <div className="text-center">{value.pic2_name}</div>
                        }
                        {!isMobile &&
                        <div className="my-1" align="Right">
                            <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" 
                            data-bs-target="#mapModalHeading">
                                <b>Show large map</b>
                            </button>
                        </div>
                        }
                        <div className="modal fade" id="mapModalHeading" aria-labelledby="mapModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">

                                    <div className="modal-header">          
                                        <h5 className="modal-title" id="mapModalLabel">Map</h5>
                                    </div>

                                    <div className="modal-body">
                                    
                                        <img src={value.pic2_link}
                                            className="img-fluid rounded" alt=""></img>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(value.in_progress || (window.location.hostname === "localhost")) &&
                            <div>
                                
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                
                                
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs mb-2">ALL count: {wineregions.length}</div>
                                </div> 
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs">FILTER count: {filterArray.length}</div>
                                </div>
                            </div>
                        }
                        
                    </div>

                    {/* kolonne2 */}
                    <div className="col-sm-7 mt-1">

                        {value.description &&

                            <div className="factText16 rounded p-2">
                                <ReactMarkdown>
                                    {value.description}              
                                </ReactMarkdown>
                            </div>

                        }
                        
                        {value.comment1 && 
                            <figure className="text-center mt-5 p-2">
                                <blockquote className="blockquote">
                                    <p>{value.comment1}</p>
                                </blockquote>
                                <figcaption className="blockquote-footer mt-2">
                                    {value.source1} <cite title="Source Title">{value.details1}</cite>
                                </figcaption>
                            </figure>
                        }
                        

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="row">   
                                        <div className="col">
                                        <h5>Search Wine regions name</h5>
                                    </div>
                                </div>
                                <div className="row">  
                                    <div className="col">
                                        <div className="search">    
                                            <DebounceInput
                                                //type="number"
                                                minLength={3}
                                                debounceTimeout={1000}
                                                value={searchString}
                                                onChange={handleSearchChange}
                                                placeholder="Search all regions" 
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <button type="button" className="btn btn-primary"  
                                        onClick={() => clearSearchField()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <h5>Google maps:</h5>
                                <div>
                                <a href={value.google_maps_wine} target="_blank" rel="noreferrer"><img src={value.google_maps_pic} alt="alt" className="img-fluid" /></a>
                                </div>

                            </div>
                        </div>
                        

                        {searchString &&
                            <div>
                                <div className='mt-3'>
                                    <div className="btn btn-info btn-xs">total hits: {filterArray.length}</div>
                                </div> 
                            </div>
                        }
                        
                       
                    </div>

                    

                        
                </div>  
                   
            </React.Fragment>
            )
    })

    const WineRegionObjects = filterArray.map(value => {

        let DescCharLimit

        if (value.desc_char_limit) {
            DescCharLimit = value.desc_char_limit
        }
        else {
            DescCharLimit = 1000
        }

        return (

            <React.Fragment key={value.id}>
                <div className="row mx-1 mt-4 border rounded border-2">

                    {/* 1 kolonne */}
                    <div className="col-sm-3 py-2">
                        <img src={value.pic_link} className="img-fluid rounded" alt="">
                        </img>

                        {isMobile &&
                            <div className="Text11 mt-1">{value.pic_link_name}</div>
                        }
                        {!isMobile &&
                            <div className="text-center Text13 mt-1">{value.pic_link_name}</div>
                        }

                        {value.pic_link2 && !isMobile &&
                        <img src={value.pic_link2} className="img-fluid rounded mt-3" alt="">
                        </img>
                        }
                        
                        {!isMobile && value.pic_link2_name &&
                            <div className="text-center Text13 mt-1">{value.pic_link2_name}</div>
                        }



                        {value.quote && value.quote_left &&
                            <div className="card bg-light px-2 pt-2 mt-3">
                                <div className="quote14 mb-3">{value.quote}</div> 
                                <div className="blockquote-footer"><i>{value.quote_source}</i></div>
                            </div>
                        }
                        
                        
                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-6 pb-3 border-right">
                        
                            <div className="pt-1">
                                <div>
                                    <h4><div className="badge bg-primary">{value.name}</div>
                                    </h4>
                                </div>
                            </div>

                            <div className="badge rounded-pill text-bg-secondary">
                            {value.pronounce}</div>

                        
                            {value.description &&
                            <div className="factText rounded my-2 p-2"> 
                                        <ReadMoreAndLess
                                            charLimit={DescCharLimit}
                                            readMoreText={" Read more ▼"}
                                            readLessText={" Read less ▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                        >
                                        {value.description}
                                    </ReadMoreAndLess> 
                                        {value.wiki &&
                                        <div className="fst-italic text-end p-1">
                                        - <a href={value.wiki}>more info..</a>
                                        </div>
                                        }
                            </div>
                            }

                            {value.wine_style &&
                                <div>
                                    <b>Wine style</b> <br />
                                    <div className="DateTxt rounded p-2">{value.wine_style}</div>
                                </div>
                            }

                            {value.comment && !value.quote_left &&
                                <div className="card bg-light px-2 pt-2 mt-3">
                                    <div className="quote14 mb-3">{value.comment}</div> 
                                    <div className="blockquote-footer"><i>{value.comment_source}</i></div>
                                </div>
                            }

                            <div className="row mx-1 mt-3">
                                
                                {value.hectars &&
                                <div className="col-sm-3 pb-2 border rounded border-2 me-2">
                                    <div className="mt-1">
                                            <div>
                                                <b>Hectars</b> <br />
                                                <div className="DateTxt rounded p-2">{value.hectars}</div>
                                            </div>
                                    </div>
                                </div>
                                }
                                
                                {value.bottles &&
                                <div className="col-sm-4 pb-2 border rounded border-2 me-2">
                                    <div className="mt-1">
                                            <div>
                                                <b>Bottles</b> <br />
                                                <div className="DateTxt rounded p-2">{value.bottles}</div>
                                            </div>
                                    </div>
                                </div>
                                }

                                {value.production && 
                                <div className="col-sm-4 pb-2 border rounded border-2 me-2">
                                    <div className="mt-1">
                                        
                                            <div>
                                                <b>Production</b> <br />
                                                <div className="DateTxt rounded p-2">{value.production}</div>
                                            </div>
                                    </div>
                                </div>
                                }

                            </div>
                        



                    </div>

                    {/* 3 kolonne */}
                    <div className="col-sm-3 py-2">
                        <div className="mt-2">
                            {value.main_grapes &&
                                <div>
                                    <b>Main grapes</b> <br />
                                    <div className="DateTxt rounded p-2">{value.main_grapes}</div>
                                </div>
                            }
                        </div>
                        <div className="mt-2">
                            {value.ref_wines &&
                                <div>
                                    <b>Reference wine</b> <br />
                                    <div className="DateTxt rounded p-2">{value.ref_wines}</div>
                                </div>
                            }
                        </div>
                        <div className="mt-2">
                            {value.best_producers &&
                                <div>
                                    <b>Best producers</b><br />
                                    <div className="DateTxt rounded p-2">
                                        <ReactMarkdown components={{ a: LinkRenderer}}>
                                            {value.best_producers}             
                                        </ReactMarkdown>
                                        
                                    </div>
                                </div>
                            }
                        </div>
                        
                        
                        
                        {value.google_maps_wine &&
                            <div className="mt-2">
                                <b>Google maps</b> <br />
                                <div className="DateTxt rounded p-2">{}
                                    <a href={value.google_maps_wine} target="_blank" rel="noreferrer">click to show on map..</a>
                                </div>
                            </div>
                        }
                        
                    </div>

                </div>
            </React.Fragment>
        )

    })

    //REFERENCES
    const referenceItems = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>
                {
                        (() => {
                        if (value.book_title) {
                        return  <div className="row px-1 my-1">
                                    <div className="col">
                                        <div>{value.author_txt} ({value.published_year}). 
                                        &nbsp;<a href={value.more_info}>{value.book_title}</a>. {value.publisher}. (book) </div>
                                    </div>
                                </div>
                        } if (!value.date_txt && value.person) {
                        return <div className="row px-1 my-1">
                                <div className="col">
                                    <a href={value.url}>
                                        {value.title}
                                    </a> ({value.person})
                                </div>
                            </div>
                        } if (!value.date_txt && !value.person) {
                            return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        }
                        if (!value.person) {
                            return <div className="row px-1 my-1">
                                        <div className="col">
                                            <a href={value.url}>
                                                {value.title}
                                            </a> ({value.date_txt})
                                        </div>
                                    </div>
                        }
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        {value.person} ({value.date_txt}). <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        })()
                    }
            </React.Fragment>
        )
    })

    return (
        <main className="my-1 py-1">
            {wineregions.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    {!isMobile &&
                    <div className="row">
                        <div className="col pb-1 me-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                    }

                    <div className="row">
                        <div className="col">
                             {WineRegionObjects} 
                        </div>
                    </div>

                    {(references.length > 0) &&
                        <div>
                                <div className="row mt-4">
                                    <div className="col">
                                            <h4>References</h4>
                                    </div>
                                </div>
                                <div className="row px-1">
                                    <div className="col">
                                        <b>
                                        <a href='https://en.wikipedia.org'>
                                            Wikipedia
                                        </a>
                                        </b>
                                    </div>
                                </div>
                            
                                <div className="row my-2">
                                    <div className="col">
                                            { referenceItems }
                                    </div>
                                </div>
                        </div>   
                    }

                    <div className="me-2" align="Right">
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </div>


                </div>
            )}
        </main>
    )

}

export default WineRegions