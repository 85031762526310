import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlWineGrapes, dbUrlWTDlist, dbUrlRegionOptions, dbURListsRefswtd, customStylesSmall } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { SearchLists, Mobile, SelectProperty } from "../misc/utils";
import Select from 'react-select';
import {DebounceInput} from 'react-debounce-input';

const WineGrapes = () => {
    const [winegrapes, setWinegrapes] = useState([])
    const [list, setList] = useState([])
    const [searchString, setSearchString] = useState('')
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [loading3, setLoading3] = useState(true)
    const [loading4, setLoading4] = useState(true)
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [references, setReferences] = useState([])
    const [errorReferences, setErrorReferences] = useState(null)
    const [regions, setRegions] = useState([])
    const [optionRegion, setOptionRegion] = useState(null)
    const [errorRegion, setErrorRegion] = useState(null)
    const [tagfilter, settagfilter] = useState('all')
    

    const params = useParams();

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbUrlWTDlist + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchGrapes = () => {
          axios.get(dbUrlWineGrapes)
          .then(response => {
            setLoading2(false)
            setWinegrapes(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefswtd + params.slug)
            .then(response => {
                setLoading3(false)
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        const fetchRegionOptions = () => {
            axios.get(dbUrlRegionOptions)
            .then(response => {
                setLoading4(false)
                setRegions(response.data)
            }).catch(errorRegion => {
                setErrorRegion(errorRegion);
            });
        }

        fetchList()
        fetchGrapes()
        fetchReferences()
        fetchRegionOptions()
      }, [params.slug])
  
    if (error) return `Error tv: ${error.message}`;
    if (errorList) return `Error list: ${errorList.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;
    if (errorRegion) return `Error Region: ${errorRegion.message}`;

    const isMobile = Mobile();

    if (loading || loading2 || loading3 || loading4) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    //console.log(references);

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                
                                    <h1>Oops! You seem to be lost.</h1>

                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    let filterArray, RegionOptions, sortedReferences, tagArray;

    sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });

    //DistrictOptions = SelectProperty(attractions, 'district')
    RegionOptions = SelectProperty(regions, 'region')

   
    const SelectedWineRegion = (selectedRegion) => {
        setOptionRegion(selectedRegion);
    }

    //tagfilter importance
    if (tagfilter === 'red') {
        tagArray = winegrapes.filter(e => e.wine_type === 'Red');  
        }
    if (tagfilter === 'white') {
        tagArray = winegrapes.filter(e => e.wine_type === 'White');  
        } 
    if (tagfilter === 'all') {
        tagArray = winegrapes;  
        }

     

    //Search
    if (searchString) {
        filterArray = SearchLists(winegrapes, 'name', searchString.toLowerCase());
        } 
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

   

    if (optionRegion) {
       filterArray = winegrapes.filter(e => e.notable_regions.includes(optionRegion.value));
    } 
    if (!optionRegion && !searchString ) {
        filterArray = winegrapes;
    }

    if (tagArray && !searchString && !optionRegion) {
        filterArray = tagArray; 
    }

    const {name, description, pic_link, page} = list[0];

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>

                <div className="ms-2"><h1>{value.name}</h1></div>
            
                <div className="row mb-2 gx-5">
                    
                    {/* kolonne1 */}
                    <div className="col-sm-4 mt-1 Description">

                        <div><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>
                        <div className="text-center">{value.pic_name}</div>
                        {value.pic2_link &&
                        <div className="mt-2 text-center">
                            <img src={value.pic2_link} alt="alt" className="img-fluid" /> 
                        </div>
                        }
                        {value.pic2_name &&
                            <div className="text-center">{value.pic2_name}</div>
                        }

                        {(value.in_progress || (window.location.hostname === "localhost")) &&
                            <div>
                                
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                
                                
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs mb-2">ALL count: {winegrapes.length}</div>
                                </div> 
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs">FILTER count: {filterArray.length}</div>
                                </div>
                            </div>
                        }
                        
                    </div>

                    {/* kolonne2 */}
                    <div className="col-sm-5 mt-1">

                        {value.description &&
                            <div className="factText14 rounded p-2">{value.description}</div>
                        }
                        
                        {value.comment1 && 
                            <figure className="text-center mt-3 p-2">
                                <blockquote className="blockquote">
                                    <p>{value.comment1}</p>
                                </blockquote>
                                <figcaption className="blockquote-footer mt-2">
                                    {value.source1} <cite title="Source Title">{value.details1}</cite>
                                </figcaption>
                            </figure>
                        }
                        <div className="mt-5">
                            <h5>Learning</h5>

                            {/* Button trigger modal */}
                            <div className="my-1 Text13">
                                <button type="button" className="btn btn-primary btn-sm Text13" 
                                data-bs-toggle="modal" data-bs-target="#tasting">
                                <b>Tasting wine</b>
                                </button>
                                <button type="button" className="btn btn-primary btn-sm Text13 ms-2" 
                                data-bs-toggle="modal" data-bs-target="#fruit">
                                <b>Fruit</b>
                                </button>
                                <button type="button" className="btn btn-primary btn-sm Text13 ms-2" 
                                data-bs-toggle="modal" data-bs-target="#wood">
                                <b>Wood</b>
                                </button>
                                <button type="button" className="btn btn-primary btn-sm Text13 ms-2" 
                                data-bs-toggle="modal" data-bs-target="#earth">
                                <b>Earth</b>
                                </button>
                                <button type="button" className="btn btn-primary btn-sm Text13 ms-2" 
                                data-bs-toggle="modal" data-bs-target="#other">
                                <b>Other</b>
                                </button>
                            </div>

                            {/* Modal - Tasting */}
                            <div className="modal fade" id="tasting" data-bs-backdrop="static" 
                            data-bs-keyboard="false" tabIndex="-1" aria-labelledby="tasting" aria-hidden="true">
                                {!isMobile &&
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title" id="tasting">
                                            WSET - Systematic Approach to Tasting Wine</h3>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body bg-light">
                                        <ReactMarkdown>
                                            {value.wine_tasting}               
                                        </ReactMarkdown>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-success" data-bs-dismiss="modal">Memorized</button>
                                    </div>
                                    </div>
                                </div>
                                }
                                {isMobile &&
                                 <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title" id="tasting">
                                            WSET - Systematic Approach to Tasting Wine</h3>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <ReactMarkdown>
                                            {value.wine_tasting}               
                                        </ReactMarkdown>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-success" data-bs-dismiss="modal">Memorized</button>
                                    </div>
                                    </div>
                                </div>

                                }
                            </div>

                            {/* Modal - Fruit */}
                            <div className="modal fade" id="fruit" data-bs-backdrop="static" 
                            data-bs-keyboard="false" tabIndex="-1" aria-labelledby="fruit" aria-hidden="true">
                                
                                
                                 <div className="modal-dialog modal-dialog-centered ">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title" id="fruit">
                                            FRUIT</h3>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body bg-light">
                                        <div className="row">
                                            <div className="col-6 border-end border-3">
                                                <ReactMarkdown>
                                                    {value.wine_fruit}               
                                                </ReactMarkdown>
                                            </div>
                                            <div className="col-6">
                                                <ReactMarkdown>
                                                    {value.wine_fruit_white}               
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-success" data-bs-dismiss="modal">Memorized</button>
                                    </div>
                                    </div>
                                </div>

                                
                            </div>

                            {/* Modal - Wood */}
                            <div className="modal fade" id="wood" data-bs-backdrop="static" 
                            data-bs-keyboard="false" tabIndex="-1" aria-labelledby="wood" aria-hidden="true">
                                
                                
                                 <div className="modal-dialog modal-dialog-centered ">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title" id="wood">
                                            WOOD</h3>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body bg-light">
                                        <ReactMarkdown>
                                            {value.wine_wood}               
                                        </ReactMarkdown>
                                    </div>
                                    
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-success" data-bs-dismiss="modal">Memorized</button>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>

                            {/* Modal - Eart */}
                            <div className="modal fade" id="earth" data-bs-backdrop="static" 
                            data-bs-keyboard="false" tabIndex="-1" aria-labelledby="earth" aria-hidden="true">
                                
                                
                                 <div className="modal-dialog modal-dialog-centered ">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title" id="earth">
                                            EARTH</h3>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body bg-light">
                                        <ReactMarkdown>
                                            {value.wine_earth}               
                                        </ReactMarkdown>
                                    </div>
                                    
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-success" data-bs-dismiss="modal">Memorized</button>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>

                            {/* Modal - Other */}
                            <div className="modal fade" id="other" data-bs-backdrop="static" 
                            data-bs-keyboard="false" tabIndex="-1" aria-labelledby="other" aria-hidden="true">
                                
                                
                                 <div className="modal-dialog modal-dialog-centered ">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title" id="other">
                                            OTHER Smells</h3>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body bg-light">
                                        <ReactMarkdown>
                                            {value.wine_other}               
                                        </ReactMarkdown>
                                    </div>
                                    
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-success" data-bs-dismiss="modal">Memorized</button>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                        
                       
                    </div>

                    {/* kolonne3 */}
                    <div className="col-sm-3 mt-1">

                        {!searchString && !optionRegion &&
                        <div className="mb-4">
                                <div className="mx-4 bg-light py-1 ps-2"><b>Click to use filter:</b></div>
                                    <div className="mb-1 mt-1 Text28">
                                        {tagfilter === 'all' &&
                                        <button type="button" className="btn btn-info me-2"
                                        onClick={() => settagfilter('all')}><b>All  <span className="badge text-bg-light rounded-pill">
                                        {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'all' &&
                                        <button type="button" className="btn btn-sm btn-info me-2"
                                        onClick={() => settagfilter('all')}><b>All </b></button>
                                        }
                                        {tagfilter === 'red' &&
                                        <button type="button" className="btn grape_red bg-danger me-2"
                                        onClick={() => settagfilter('red')}><b>Red <span className="badge text-bg-light rounded-pill">
                                        {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'red' &&
                                        <button type="button" className="btn btn-sm grape_red bg-danger me-2"
                                        onClick={() => settagfilter('red')}><b>Red</b></button>
                                        }
                                        {tagfilter === 'white' &&
                                        <button type="button" className="btn grape_white bg-light me-2"
                                        onClick={() => settagfilter('white')}><b>White <span className="badge text-bg-secondary rounded-pill">
                                        {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'white' &&
                                        <button type="button" className="btn btn-sm grape_white bg-light me-2"
                                        onClick={() => settagfilter('white')}><b>White</b></button>
                                        }
                                        
                                    </div> 
                        </div>
                        }
                            
                        

                        {!searchString && (tagfilter === 'all') &&
                            <div>
                                    <div className="row">   
                                            <div className="col">
                                            <h4>Regions</h4>
                                        </div>
                                    </div>
                                    <div className="row mb-4">  
                                        <div className="col-8">
                                            <Select
                                                className="select"
                                                value={optionRegion}
                                                onChange={SelectedWineRegion}
                                                options={RegionOptions}
                                                placeholder='Select a region..'
                                                styles={customStylesSmall}
                                            />
                                        </div>
                                        <div className="col-4 pl-2">
                                            <button  className="btn btn-primary btn-sm"  onClick={() => setOptionRegion(null)} >Reset</button>
                                        </div>
                                    </div>
                            </div>
                        }  
                        
                        {!optionRegion && (tagfilter === 'all') &&
                        <div>
                            <div className="row">   
                                    <div className="col">
                                    <h4>Search</h4>
                                </div>
                            </div>
                            <div className="row">  
                                <div className="col-9">
                                    <div className="search">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={1000}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all grapes" 
                                        />
                                    </div>
                                </div>
                                <div className="col-3 pl-0">
                                    <button type="button" className="btn btn-primary"  
                                    onClick={() => clearSearchField()}>Reset</button>
                                </div>
                            </div>
                        </div>
                        }

                        {searchString &&
                            <div>
                                <div className='mt-3'>
                                    <div className="btn btn-info btn-xs">total hits: {filterArray.length}</div>
                                </div> 
                            </div>
                        }
                    
                    </div>

                        
                </div>
                
                   
            </React.Fragment>
            )
    })

    const GrapesObjects = filterArray.map(value => {

        let DescCharLimit

        if (value.desc_char_limit) {
            DescCharLimit = value.desc_char_limit
        }
        else {
            DescCharLimit = 1000
        }

        return (

            <React.Fragment key={value.id}>
                <div className="row mx-1 mt-4 border rounded border-2">

                    {/* 1 kolonne */}
                    <div className="col-sm-3 py-2">
                        <img src={value.pic_link} className="img-fluid rounded" alt="">
                        </img>

                        {isMobile &&
                            <div className="Text11 mt-1">{value.pic_name}</div>
                        }
                        {!isMobile &&
                            <div className="text-center Text13 mt-1">{value.pic_name}</div>
                        }

                        {value.quote && value.quote_left &&
                            <div className="card bg-light px-2 pt-2 mt-3">
                                <div className="quote14 mb-3">{value.quote}</div> 
                                <div className="blockquote-footer"><i>{value.quote_source}</i></div>
                            </div>
                        }
                        
                        
                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-6 pb-3 border-right">
                        
                            <div className="pt-1">
                                <div>
                                    <h4><div className="badge bg-primary">{value.name}</div>
                                    </h4>
                                </div>
                            </div>

                            {value.wine_type === 'Red' &&
                            <div className="badge grape_red bg-danger me-3">
                            {value.wine_type}</div>
                            }
                           
                           {value.wine_type === 'White' &&
                            <div className="badge grape_white bg-light me-3">
                            {value.wine_type}</div>
                            }

                            

                            <div className="badge rounded-pill text-bg-secondary">
                            {value.pronounced}</div>

                        
                            {value.description &&
                            <div className="factText rounded my-2 p-2"> 
                                        <ReadMoreAndLess
                                            charLimit={DescCharLimit}
                                            readMoreText={" Read more ▼"}
                                            readLessText={" Read less ▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                        >
                                        {value.description}
                                    </ReadMoreAndLess> 
                                        {value.wiki &&
                                        <div className="fst-italic text-end p-1">
                                        - <a href={value.wiki}>more info..</a>
                                        </div>
                                        }
                            </div>
                            }

                            {value.wine_style &&
                                <div>
                                    <b>Wine style</b> <br />
                                    <div className="DateTxt rounded p-2">{value.wine_style}</div>
                                </div>
                            }

                            {value.quote && !value.quote_left &&
                                <div className="card bg-light px-2 pt-2 mt-3">
                                    <div className="quote14 mb-3">{value.quote}</div> 
                                    <div className="blockquote-footer"><i>{value.quote_source}</i></div>
                                </div>
                            }

                            <div className="row mx-1 mt-3">
                                
                                {value.fruit &&
                                <div className="col-sm-3 pb-2 border rounded border-2 me-2">
                                    <div className="mt-1">
                                        {value.fruit &&
                                            <div>
                                                <b>FRUIT</b> <br />
                                                <div className="DateTxt rounded p-2">{value.fruit}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                }
                                
                                {value.wood &&
                                <div className="col-sm-4 pb-2 border rounded border-2 me-2">
                                    <div className="mt-1">
                                            <div>
                                                <b>WOOD</b> <br />
                                                <div className="DateTxt rounded p-2">{value.wood}</div>
                                            </div>
                                    </div>
                                </div>
                                }

                                {value.earth && 
                                <div className="col-sm-4 pb-2 border rounded border-2 me-2">
                                    <div className="mt-1">
                                        
                                            <div>
                                                <b>EARTH</b> <br />
                                                <div className="DateTxt rounded p-2">{value.earth}</div>
                                            </div>
                                    </div>
                                </div>
                                }

                            </div>
                        



                    </div>

                    {/* 3 kolonne */}
                    <div className="col-sm-3 py-2">
                        <div className="mt-2">
                            {value.top_countries &&
                                <div>
                                    <b>Top countries</b> <br />
                                    <div className="DateTxt rounded p-2">{value.top_countries}</div>
                                </div>
                            }
                        </div>
                        <div className="mt-2">
                            {value.notable_regions &&
                                <div>
                                    <b>Notable regions</b> <br />
                                    <div className="DateTxt rounded p-2">{value.notable_regions}</div>
                                </div>
                            }
                        </div>
                        <div className="mt-2">
                            {value.notable_wines &&
                                <div>
                                    <b>Notable wines</b> <br />
                                    <div className="DateTxt rounded p-2">{value.notable_wines}</div>
                                </div>
                            }
                        </div>
                        <div className="mt-2">
                            {value.primary_flavors &&
                                <div>
                                    <b>Primary flavors</b> <br />
                                    <div className="DateTxt rounded p-2">{value.primary_flavors}</div>
                                </div>
                            }
                        </div>
                        <div className="mt-2">
                            {value.taste &&
                                <div>
                                    <b>Taste</b> <br />
                                    <div className="DateTxt rounded p-2">{value.taste}</div>
                                </div>
                            }
                        </div>


                        {value.highlights &&
                            <div>
                                <div className="PeopleHeader py-1 ps-2 rounded"><b>Highlights</b></div>
                                <div className="pt-2 border-top Text13">
                                    <ReactMarkdown>
                                        {value.highlights}               
                                    </ReactMarkdown>
                                </div>
                            </div>
                        }
                        
                            
                        
                        
                        {value.nearest_tube_station &&
                            <div>
                                {params.slug === 'london' &&
                                <div>
                                    <b>Tube</b> <br />
                                </div>
                                }
                                {params.slug !== 'london' &&
                                <div>
                                    <b>Metro</b> <br />
                                </div>
                                }
                                <div className="DateTxt rounded py-1 px-2">{value.nearest_tube_station}</div>
                            </div>
                        }
                        {value.google_maps &&
                            <div className="mt-2">
                                <b>Google maps</b> <br />
                                <div className="DateTxt rounded p-2">{}
                                    <a href={value.google_maps} target="_blank" rel="noreferrer">click to show on map..</a>
                                </div>
                            </div>
                        }
                        
                    </div>
                        


                </div>
            </React.Fragment>
        )

    })

    //REFERENCES
    const referenceItems = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>
                {
                        (() => {
                        if (value.book_title) {
                        return  <div className="row px-1 my-1">
                                    <div className="col">
                                        <div>{value.author_txt} ({value.published_year}). 
                                        &nbsp;<a href={value.more_info}>{value.book_title}</a>. {value.publisher}. (book) </div>
                                    </div>
                                </div>
                        } if (!value.date_txt && value.person) {
                        return <div className="row px-1 my-1">
                                <div className="col">
                                    <a href={value.url}>
                                        {value.title}
                                    </a> ({value.person})
                                </div>
                            </div>
                        } if (!value.date_txt && !value.person) {
                            return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        }
                        if (!value.person) {
                            return <div className="row px-1 my-1">
                                        <div className="col">
                                            <a href={value.url}>
                                                {value.title}
                                            </a> ({value.date_txt})
                                        </div>
                                    </div>
                        }
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        {value.person} ({value.date_txt}). <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        })()
                    }
            </React.Fragment>
        )
    })

    return (
        <main className="my-1 py-1">
            {winegrapes.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    {!isMobile &&
                    <div className="row">
                        <div className="col pb-1 me-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                    }

                    <div className="row">
                        <div className="col">
                             {GrapesObjects} 
                        </div>
                    </div>

                    {(references.length > 0) &&
                        <div>
                                <div className="row mt-4">
                                    <div className="col">
                                            <h4>References</h4>
                                    </div>
                                </div>
                                <div className="row px-1">
                                    <div className="col">
                                        <b>
                                        <a href='https://en.wikipedia.org'>
                                            Wikipedia
                                        </a>
                                        </b>
                                    </div>
                                </div>
                            
                                <div className="row my-2">
                                    <div className="col">
                                            { referenceItems }
                                    </div>
                                </div>
                        </div>   
                    }

                    <div className="me-2" align="Right">
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </div>


                </div>
            )}
        </main>
    )

}

export default WineGrapes