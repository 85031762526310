import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlWandPCharacters, customStyles2 } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import Select from 'react-select';
//import ReadMoreAndLess from "react-read-more-less";
//import SEO from "../../SEO";
import {Link} from 'react-router-dom';
//import ReactMarkdown from 'react-markdown';
//import dayjs from "dayjs";

const BookCharacters = () => {
    const [Characters, setCharacters] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)

    let filterCharacters

    //console.log(params);
    
    useEffect(() => {
        const fetchCharacters = () => {
          axios.get(dbUrlWandPCharacters)
          .then(response => {
            setLoading(false)
            setCharacters(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        fetchCharacters()
      }, [])
  
    if (error) return `Error: ${error.message}`;

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
        //console.log(`Option selected:`, selectedOption);
        };

    const clearFilterCategory = (e) => {
        setSelectedCategory(null);
    } 

    if (selectedCategory) {
        filterCharacters = Characters.filter(e => e.family === selectedCategory.value);
        }  
    if (!selectedCategory) {
        filterCharacters = Characters;
    }

    //remove null
    const NullCategory = Characters.filter((value) => value.family != null);
    //get only family
    const OnlyCategory = NullCategory.map(item => item.family);
    //unique family + count
    const uniqueCategory = OnlyCategory.reduce((b,c) => (
        (b[b.findIndex(d => d.family===c)] ||
        // eslint-disable-next-line 
        b[b.push({family: c, count: 0})-1]).count++,b), 
        []
    );
    const uniqueCategorySorted = uniqueCategory.sort(function(a, b){
       return b.count-a.count
   })
    //map Family with count to FamilyOptions
    const CategoryOptions = uniqueCategorySorted.map(value => { 
        return {value: value.family, label: value.family + ' (' + value.count + ')'}; 
    });


    if (loading) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (Characters.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                
                                    <h1>Oops! You seem to be lost.</h1>
                                    <p>Here are some helpful links:</p>

                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                        <li><Link to='/allbooks'>All Books</Link></li>
                                        <li><Link to='/allquotes'>All Quotes</Link></li>
                                    </ul>
                                    
                                
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }


    const CharacterItems = filterCharacters.map(value => {

        return (

            <React.Fragment key={value.id}>
                <div className="row pt-3 pb-3 border-bottom border-top border-info">

                    {/* kolonne 1 */}
                    <div className="col-sm-4">
                        <div className='PeopleName text-center'><b>{value.name}</b></div>
                        {
                            (() => {
                            if (value.short_name) {
                            return <div className="my-1">Short name: <b>{value.short_name}</b></div>
                            }
                            return 
                            })()
                        }
                        

                        <div className="mt-4">Family: &nbsp;
                        <button type="button" className="btn btn-info btn-sm">{value.family}</button>
                        </div>

                    </div>

                    {/* kolonne 2 */}
                    <div className="col-sm-8 mt-1">
                        
                        {value.description} 
                        
                    </div>

                    

                </div>

                

            </React.Fragment>

        )

    })
    
    return (
        <main className="my-1 py-1">
            {Characters.length > 0 && (
                <div className="container">

                    <div className="row mt-3">
                        <div className="col">
                            <h2>War and Peace - Principal characters</h2>
                        </div>
                    </div>

                    <div className="row m-3">
                        <div className="col-sm-5">
                            <p>"War and Peace" tells the story of five families — the Bezukhovs, the Bolkonskys, the Rostovs, the Kuragins, and the Drubetskoys.</p>
                            <p><b>The main characters are:</b></p>      
                        </div>
                        <div className="col-sm-4">
                            <div><img src='https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/68d7b619-0c84-45c4-9edb-ab0fa7e25d00/public' 
                                 alt="alt" className="rounded img-fluid" /> </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="row">   
                                    <div className="col">
                                        <h4>Filter</h4>
                                </div>
                            </div>

                            <div className="row">  
                                <div className="col">
                                        <Select
                                            className="select"
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            //onChange={selectedOptionArtist}
                                            options={CategoryOptions}
                                            placeholder='select category'
                                            styles={customStyles2}
                                            autoFocus={true}
                                        />
                                        <button  className="btn btn-primary btn-sm"  
                                        onClick={() => clearFilterCategory()} >Reset</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                    


                    <div className="row">
                        <div className="col quote14">
                            {CharacterItems}
                        </div>
                    </div>

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>


                </div>
            )}
        </main>
    )
}

export default BookCharacters