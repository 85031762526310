import React from "react";
import Header from './components/Header';
import {
  Routes,
  Route
} from "react-router-dom";
import NotFound from "./NotFound";
import Books from "./components/Books/Books";
import QuoteLists from "./components/Quotes/QuoteLists";
import BookSummaries from "./components/Books/BookSummaries";
import TravelPlanning from "./components/travel/TravelPlanning";
import PeopleUnrankedLists from "./components/people/PeopleUnrankedLists";
import QuotesAll from "./components/Quotes/QuotesAll";
import BookCharacters from "./components/Books/BookCharacters"
import TVLists from "./components/tv/TVLists";
import Lists from "./components/Lists";
import TravelLists from "./components/travel/TravelLists";
import TravelAttractions from "./components/travel/TravelAttractions";
import WineGrapes from "./components/wine/WineGrapes";
import WineRegions from "./components/wine/WineRegions";
import KindleHighlights from "./components/Kindle_highlights";
import BooksHighlights from "./components/Books/BooksHighlights";

export default function App() {
  return (
    <div className="App">

            <Header />

              <Routes>

                   <Route path="/" element={<QuotesAll />} />

                   <Route path="/books/:slug" 
                   element={<Books BookAPI={'books/'} />} />
                   <Route path="/books-ranked/:slug" 
                   element={<Books BookAPI={'books-ranked/'} />} />

                  <Route path="/kindlehighlights" element={<KindleHighlights />} />

                  <Route path="/books-kindle-highlights/:slug" 
                   element={<BooksHighlights BookAPI={'kindlehighlightsobjects/'} />} />
                   

                   <Route path="/books/war-and-peace-principal-characters" element={<BookCharacters />} />

                   <Route path="/moremolecule" element={<BookSummaries BookAPI={'bookquotes/27'} />} />

                   <Route path="/allquotes" element={<QuotesAll />} />

                   <Route path="/quotes/:slug" element={<QuoteLists />} />

                   <Route path="/travel-planning/:slug" element={<TravelPlanning />} />

                   <Route path="/travel/:slug" element={<TravelLists />} />

                   {/* <Route path="/travel-destination/:slug" element={<TravelAttractions />} /> */}

                   <Route path="/travel-lists/:slug" element={<TravelAttractions />} />


                   <Route path="/lists/people/:slug" element={<PeopleUnrankedLists />} />

                   <Route path="/tv/:slug" element={<TVLists />} />

                   <Route path="/wine/:slug" element={<WineGrapes />} />

                   <Route path="/wine-regions/:slug" element={<WineRegions />} />

                   <Route path="/lists" element={<Lists />} />
                   

                  {/* TIL SLUTT */}
                   <Route path="*" element={<NotFound />} />
                  
                  {/* 
                 
                  <Route
                    path="/quotes/:slug"
                    render={({ match }) => <QuoteLists slug={match.params.slug} />}
                    />

                   */}

              </Routes>

          

        </div>
  );
}