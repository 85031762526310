import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {

    return (

        
    <div>
        {/* REROUTING */}
         {/* <Route exact path="/egil">
            <Navigate to="/allbooks" />
        </Route> */}

        <main className="my-1 py-1">
            <div className="container">  
                <div className="row pt-1">
                    <div className="col">
                        <h1>Oops! You seem to be lost.</h1>
                        <p>Here are some helpful links:</p>

                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/allbooks'>All Books</Link></li>
                            <li><Link to='/allquotes'>All Quotes</Link></li>
                        </ul>
                        
                    </div>
                </div>
            </div>
        </main>
    </div>
    );
};

export default NotFound;